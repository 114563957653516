<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">
    <div>
      <Breadcrumbs class="inlineblock" />
    </div>

    <v-container>
      <v-row>
        <v-col cols="4">
          <v-card tile class="pa-5">
            <v-row v-if="selectedVideo">
              <v-spacer />
              <v-btn rounded small color="primary" dark style="text-transform:none" @click="renameVideo">
                {{ $t('general.rename') }}
              </v-btn>
              <v-btn style="text-transform:none" rounded small color="red" dark @click="confirmDeleteVideo = true">
                {{ $t('general.delete') }}
              </v-btn>
            </v-row>
            <v-subheader>{{ $t('video.videos') }}</v-subheader>
            <v-text-field v-model="searchVideo" append-icon="mdi-magnify" dense></v-text-field>
            <v-list dense>
              <v-list-item v-for="(item, i) in filteredVideos" :key="i" @click="selectVideo(item)"
                           :class="{ 'active-video': selectedVideo && item.id == selectedVideo.id }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn @click="isAddingVideo = true" class="ma-2 add-video-btn" rounded color="primary" dark>{{ $t('video.add_video') }}</v-btn>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card v-if="selectedVideo" class="pa-5">
            <v-row style="padding: 15px 0px 0px 15px">
              <v-col cols="6">
                <v-text-field
                  :label="$t('video.company')"
                  dense
                  v-model="selectedVideo.company"
                  maxlength="255"
                  hide-details
                  @change="saveVideoCompany()"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="$t('general.created_by')"
                  dense
                  v-model="selectedVideo.creator"
                  maxlength="255"
                  hide-details
                  @change="saveVideoCreator()"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col>
                <v-subheader style="font-size: 0.75rem">{{ $t("video.video_translations") }}</v-subheader>
                <v-data-table
                  :headers="videoTranslationHeaders"
                  :items="selectedVideoTranslations"
                  @click:row="editVideoTranslation"
                  item-key="index"
                  disable-pagination
                  hide-default-footer
                ></v-data-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                dense
                :items="videoCategories"
                :label="$t('video.video_categories')"
                item-text="name"
                item-value="id"
                return-object
                v-model="selectedVideo.editVideoCategories"
                multiple
                chips
                @change="videoCategoriesChanged()"
              ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                dense
                :items="engineeringDepartments"
                :label="$t('general.engineering_departments')"
                item-text="name"
                item-value="id"
                return-object
                v-model="selectedVideo.editVideoEngineeringDepartments"
                multiple
                chips
                @change="engineeringDepartmentsChanged()"
              ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                dense
                :items="roles"
                :label="$t('users.role')"
                item-text="name"
                item-value="id"
                return-object
                v-model="selectedVideo.editVideoRoles"
                multiple
                chips
                @change="rolesChanged()"
              ></v-autocomplete>
              </v-col>
            </v-row>

            <v-btn @click="sendNotification()" class="ma-2 add-video-btn" rounded color="primary" dark>{{ $t('video.sendNotification') }}</v-btn>
          </v-card>
          <br />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-card tile class="pa-5">
            <v-row v-if="selectedVideoCategory">
              <v-spacer />
              <v-btn rounded small color="primary" dark style="text-transform:none" @click="renameVideoCategory">
                {{ $t('general.rename') }}
              </v-btn>
              <v-btn style="text-transform:none" rounded small color="red" dark @click="confirmDeleteVideoCategory = true">
                {{ $t('general.delete') }}
              </v-btn>
            </v-row>
            <v-subheader>{{ $t('video.video_categories') }}</v-subheader>
            <v-list dense>
              <v-list-item v-for="(item, i) in videoCategories" :key="i" @click="selectVideoCategory(item)"
                           :class="{ 'active-video': selectedVideoCategory && item.id == selectedVideoCategory.id }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn @click="isAddingVideoCategory = true" class="ma-2 add-video-btn" rounded color="primary" dark>{{ $t('video.add_video_category') }}</v-btn>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card v-if="selectedVideoCategory" class="pa-5">
            <v-subheader style="font-size: 0.75rem">{{
              $t("video.video_category_translations")
            }}</v-subheader>
            <v-data-table
              :headers="videoCategoryTranslationHeaders"
              :items="selectedVideoCategoryTranslations"
              @click:row="editVideoCategoryTranslation"
              item-key="index"
              disable-pagination
              hide-default-footer
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <ConfirmDialog
      v-if="confirmDeleteVideo"
      v-model="confirmDeleteVideo"

      :title="$t('confirm.head')"
      :description="$t('video.confirm_delete_video')"

      :cancelName="$t('reportInfo.no')"
      :actionName="$t('reportInfo.yes')"
      :actionFunction="deleteVideo"
    />
    <NamingDialog
      v-if="isEditingVideoName"
      v-model="isEditingVideoName"

      :baseValue="editedVideo.name"

      :headlineName="$t('video.rename_video') + ' ' + editedVideo.name"
      :typeName="$t('general.name')"

      :cancelName="$t('general.cancel')"                
      :cancelFunction="() => (isEditingVideoName = false)"
      :actionName="$t('general.rename')"
      :actionFunction="saveVideoName"
    />
    <NamingDialog
      v-model="isAddingVideo"
      v-if="isAddingVideo"

      :headlineName="$t('video.new_video')"
      :typeName="$t('general.name')"

      :cancelName="$t('general.cancel')"                
      :cancelFunction="() => (isAddingVideo = false)"
      :actionName="$t('general.add')"
      :actionFunction="addNewVideo"
    />

    <ConfirmDialog
      v-if="confirmDeleteVideoCategory"
      v-model="confirmDeleteVideoCategory"

      :title="$t('confirm.head')"
      :description="$t('video.confirm_delete_video_category')"

      :cancelName="$t('reportInfo.no')"
      :actionName="$t('reportInfo.yes')"
      :actionFunction="deleteVideoCategory"
    />
    <NamingDialog
      v-if="isEditingVideoCategory"
      v-model="isEditingVideoCategory"

      :baseValue="editedVideoCategory.name"

      :headlineName="$t('video.rename_video_category') + ' ' + editedVideoCategory.name"
      :typeName="$t('general.name')"

      :cancelName="$t('general.cancel')"                
      :cancelFunction="() => (isEditingVideoCategory = false)"
      :actionName="$t('general.rename')"
      :actionFunction="saveVideoCategory"
    />
    <NamingDialog
      v-model="isAddingVideoCategory"
      v-if="isAddingVideoCategory"

      :headlineName="$t('video.new_video_category')"
      :typeName="$t('general.name')"

      :cancelName="$t('general.cancel')"                
      :cancelFunction="() => (isAddingVideoCategory = false)"
      :actionName="$t('general.add')"
      :actionFunction="addNewVideoCategory"
    />

    <!-- VIDEO TRANSLATION -->
    <v-dialog
      v-if="isEditingVideoTranslation"
      v-model="isEditingVideoTranslation"
      persistent
      max-width="1200"
    >
      <v-card>
        <v-card-title>
          {{ $t("video.video_translations") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('video.title')"
                outlined
                v-model="editedVideoTranslation.title"
                maxlength="255"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                :items="availableLanguages"
                :label="$t('general.language')"
                v-model="editedVideoTranslation.language"
                disabled
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('video.url')"
                outlined
                v-model="editedVideoTranslation.url"
                maxlength="2048"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4" cols="12">
              <v-text-field
                :label="$t('video.duration')"
                outlined
                v-model="editedVideoTranslation.duration"
                maxlength="45"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="isEditingVideoTranslation = false"
            class="no-transform"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            @click="saveVideoTranslation"
            class="no-transform"
          >
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VIDEO CATEGORY TRANSLATION -->
    <v-dialog
      v-if="isEditingVideoCategoryTranslation"
      v-model="isEditingVideoCategoryTranslation"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          {{ $t("projecttypes.edit_translation") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('general.name')"
            outlined
            v-model="editedVideoCategoryTranslation.name"
            v-on:keyup.enter="saveVideoCategoryTranslation()"
            v-on:keydown.enter.prevent
            maxlength="255"
          ></v-text-field>
          <v-select
            :items="availableLanguages"
            :label="$t('general.language')"
            v-model="editedVideoCategoryTranslation.language"
            disabled
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="isEditingVideoCategoryTranslation = false"
            class="no-transform"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            @click="saveVideoCategoryTranslation"
            class="no-transform"
          >
            {{ $t("general.rename") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { parseError, getFormattedName } from "@/utils/utils";

import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import NamingDialog from '@/components/dialogs/NamingDialog.vue';
import authHeader from "@/services/auth-header";
import constants from "@/constants";
const axios = require('axios');

var API_URL = constants.API_URL + "settings/";

export default {
  name: "VideosConfig",
  components: {
    Breadcrumbs,
    ConfirmDialog,
    NamingDialog
  },
  data: () => {
      return {
         videos: null,
         videoCategories: null,
         selectedVideo: null,
         isAddingVideo: false,
         isEditingVideo: false,
         isEditingVideoName: false,
         editedVideo: null,
         confirmDeleteVideo: false,
         editedVideoTranslation: null,
         isEditingVideoTranslation: false,
         selectedVideoCategory: null,
         isAddingVideoCategory: false,
         isEditingVideoCategory: false,
         editedVideoCategory: null,
         confirmDeleteVideoCategory: false,
         isEditingVideoCategoryTranslation: false,
         editedVideoCategoryTranslation: null,
         categoryTranslations: null,
         availableLanguages: [],
         forceVideoCategoryTranslationsUpdate: 0,
         forceVideoTranslationsUpdate: 0,
         engineeringDepartments: [],
         roles: [],
         searchVideo: ""
      };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      currentUser: state => state.currentUser,
    }),
    ...mapGetters({
    }),
    filteredVideos() {
      if (this.searchVideo.length == 0) {
        return this.videos;
      }
      return this.videos.filter(v => v.name && v.name.toLowerCase().includes(this.searchVideo.toLowerCase()));
    },
    videoTranslationHeaders() {
      return [ { text: this.$t('general.language'), value: "language" }, 
               { text: this.$t('video.duration'), value: "duration" }, 
               { text: this.$t('video.title'), value: "title" }, 
               { text: this.$t('video.url'), value: "url" } ];
    },
    videoCategoryTranslationHeaders() {
      return [ { text: this.$t('general.name'), value: "name", width: "50%" }, 
               { text: this.$t('general.language'), value: "language" } ];
    },
    selectedVideoTranslations() {
      var translations = [];

      this.forceVideoTranslationsUpdate;

      if (this.selectedVideo && this.selectedVideo.videos_translations) {
        for (var i = 0; i < this.availableLanguages.length; i++) {
          var translation = this.selectedVideo.videos_translations.find(t => t.language == this.availableLanguages[i]);

          translations.push({
            id: translation ? translation.id : -1,
            duration: translation ? translation.duration : null,
            title: translation ? translation.title : null,
            url: translation ? translation.url : null,
            language: this.availableLanguages[i],
          });
        }
      }

      return translations;
    },
    selectedVideoCategoryTranslations() {
      var translations = [];

      this.forceVideoCategoryTranslationsUpdate;

      if (this.selectedVideoCategory && this.selectedVideoCategory.videos_categories_translations) {
        for (var i = 0; i < this.availableLanguages.length; i++) {
          var translation = this.selectedVideoCategory.videos_categories_translations.find(t => t.language == this.availableLanguages[i]);
          var id = -1;
          var name = "";

          if (translation) {
            id = translation.id;
            name = translation.name;
          }

          translations.push({
            id: id,
            name: name,
            language: this.availableLanguages[i],
          });
        }
      }

      return translations;
    },
  },
  methods: {
    ...mapMutations({
      openToast: "openToast",
    }),
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
    }),
    getUserFormattedName(user) {
      if (!user) {
        return null;
      }
      return getFormattedName(user.firstname, user.lastname);
    },

    async sendNotification(){
      try {
        // Validation
        let errorMessage = '';

        if (this.selectedVideo.notificationSend) errorMessage = 'Notification already sent';

        if (this.selectedVideo.videos_translations.filter(e=>(e.title && e.url)).length != this.availableLanguages.length) errorMessage = this.$t('video.allLanguagesM​mustBeCompleted');

        if (!errorMessage){
          let res = await axios.post(API_URL + "saveVideo/sendNotification/" + this.selectedVideo.id, {}, { headers: authHeader() });
          if (res.data.message == 'ok'){
            this.selectedVideo.notificationSend = true;
            this.openToast({ message: 'Notification sent!', duration: 15000 })
          }
          else{
            errorMessage = 'Error: sendNotification()';
          }
        }

        if (errorMessage){
          this.openToast({ message: errorMessage, duration: 15000, color: "red" })
          return false;
        }
      }
      catch (err) {
        this.openToast({ message: parseError(err), duration: 15000, color: "red" });
      }
    },

    async selectVideo(video) {
      if (this.selectedVideo && this.selectedVideo.id == video.id) {
        return;
      }

      let res = await axios.get(API_URL + "video/" + video.id, { headers: authHeader() });
      //console.log('selectVideo res', res)
      if (res && res.data) {
        this.selectedVideo = res.data;

        this.selectedVideo.editVideoCategories = JSON.parse(JSON.stringify(this.selectedVideo.videos_categories, null, 2));
        this.selectedVideo.editVideoEngineeringDepartments = JSON.parse(JSON.stringify(this.selectedVideo.engineering_departments, null, 2));
        this.selectedVideo.editVideoRoles = JSON.parse(JSON.stringify(this.selectedVideo.roles, null, 2));
      }
    },
    renameVideo() {
      this.isEditingVideoName = true;
      this.editedVideo = JSON.parse(JSON.stringify(this.selectedVideo));
    },
    saveVideoName(newVideoName) {
      this.isEditingVideoName = false;

      let changes = {
        videoId: this.editedVideo.id,
        name: newVideoName,
      };
      this.saveVideoChanges(this.editedVideo.id, changes);
    },
    addNewVideo(newVideoName) {
      this.isAddingVideo = false;

      let videoId = -1;
      let changes = {
        videoId: videoId,
        name: newVideoName,
      };

      this.saveVideoChanges(videoId, changes);
    },
    saveVideoCompany() {
      let changes = {
        videoId: this.selectedVideo.id,
        company: this.selectedVideo.company,
      };
      this.saveVideoChanges(this.selectedVideo.id, changes);
    },
    saveVideoCreator() {
      let changes = {
        videoId: this.selectedVideo.id,
        creator: this.selectedVideo.creator,
      };
      this.saveVideoChanges(this.selectedVideo.id, changes);
    },
    async saveVideoChanges(videoId, changes) {
      try {

        let res = await axios.post(API_URL + "saveVideo", changes, { headers: authHeader() });
        //console.log('saveVideo res', res)
        if (res.status == 200) {
          let videoIndex = this.videos.findIndex(t => t.id == videoId);
          if (videoIndex >= 0) {
            this.selectedVideo.name = res.data.name;
            this.videos[videoIndex].name = this.selectedVideo.name;
          }
          else {
            this.selectedVideo = res.data;
            this.selectedVideo.createdBy = this.currentUser;
            this.selectedVideo.videos_translations = [];
            this.selectedVideo.videos_categories = [];
            this.selectedVideo.engineering_departments = [];
            this.selectedVideo.roles = [];
            this.videos.push(this.selectedVideo);
          }
        }
        else {
          this.openToast({ message: parseError(res), duration: 15000, color: "red" });
        }
      }
      catch (err) {
        this.openToast({ message: parseError(err), duration: 15000, color: "red" });
      }
    },
    async deleteVideo() {
      this.confirmDeleteVideo = false;

      let res = await axios.delete(API_URL + "deleteVideo/" + this.selectedVideo.id, { headers: authHeader() });
      //console.log('deleteVideo res', res)
      if (res && res.data) {
        let videoIndex = this.videos.findIndex(t => t.id == this.selectedVideo.id);
        if (videoIndex >= 0) {
          this.videos.splice(videoIndex, 1);
          this.selectedVideo = null;
        }
      }
    },
    editVideoTranslation(translation) {
      this.isEditingVideoTranslation = true;
      this.editedVideoTranslation = JSON.parse(JSON.stringify(translation));
    },
    async saveVideoTranslation() {
      let changes = {
        videoId: this.selectedVideo.id,
        videoTranslationId: this.editedVideoTranslation.id,
        duration: this.editedVideoTranslation.duration,
        title: this.editedVideoTranslation.title,
        url: this.editedVideoTranslation.url,
        language: this.editedVideoTranslation.language,
      };
      this.isEditingVideoTranslation = false;

      let res = await axios.post(API_URL + "saveVideoTranslation", changes, { headers: authHeader() });
      //console.log('saveVideoTranslation res', res)
      if (res.status == 200) {
        let translationIndex = this.selectedVideo.videos_translations.findIndex(t => t.language == this.editedVideoTranslation.language);
        if (translationIndex >= 0) {
          this.selectedVideo.videos_translations[translationIndex] = res.data;
        }
        else {
          this.selectedVideo.videos_translations.push(res.data);
        }
        this.forceVideoTranslationsUpdate++;
      }
      else {
        this.openToast({ message: parseError(res), duration: 15000, color: "red" });
      }
    },
    async selectVideoCategory(videoCategory) {
      if (this.selectedVideoCategory && this.selectedVideoCategory.id == videoCategory.id) {
        return;
      }
      let res = await axios.get(API_URL + "videoCategory/" + videoCategory.id, { headers: authHeader() });
      if (res && res.data) {
        this.selectedVideoCategory = res.data;
      }
    },
    renameVideoCategory() {
      this.isEditingVideoCategory = true;
      this.editedVideoCategory = JSON.parse(JSON.stringify(this.selectedVideoCategory));
    },
    async saveVideoCategoryChanges(videoCategoryId, changes) {

      try {
        let res = await axios.post(API_URL + "saveVideoCategory", changes, { headers: authHeader() });
        //console.log('saveVideoCategory res', res)
        if (res.status == 200) {
          let videoCategoryIndex = this.videoCategories.findIndex(t => t.id == videoCategoryId);
          if (videoCategoryIndex >= 0) {
            this.selectedVideoCategory.name = res.data.name;
            this.videoCategories[videoCategoryIndex].name = this.selectedVideoCategory.name;
          }
          else {
            this.selectedVideoCategory = res.data;
            this.selectedVideoCategory.videos_categories_translations = [];
            this.videoCategories.push(this.selectedVideoCategory);
          }
        }
        else {
          this.openToast({ message: parseError(res), duration: 15000, color: "red" });
        }
      }
      catch (err) {
        this.openToast({ message: parseError(err), duration: 15000, color: "red" });
      }
    },
    saveVideoCategory(newVideoCategoryName) {
      this.isEditingVideoCategory = false;

      let changes = {
        videoCategoryId: this.editedVideoCategory.id,
        name: newVideoCategoryName,
      };
      this.saveVideoCategoryChanges(this.editedVideoCategory.id, changes);
    },
    addNewVideoCategory(newVideoCategoryName) {
      this.isAddingVideoCategory = false;

      let videoCategoryId = -1;
      let changes = {
        videoCategoryId: videoCategoryId,
        name: newVideoCategoryName,
      };

      this.saveVideoCategoryChanges(videoCategoryId, changes);
    },
    async deleteVideoCategory() {
      this.confirmDeleteVideoCategory = false;

      let res = await axios.delete(API_URL + "deleteVideoCategory/" + this.selectedVideoCategory.id, { headers: authHeader() });
      //console.log('deleteVideoCategory res', res)
      if (res && res.data) {
        let videoCategoryIndex = this.videoCategories.findIndex(t => t.id == this.selectedVideoCategory.id);
        if (videoCategoryIndex >= 0) {
          this.videoCategories.splice(videoCategoryIndex, 1);
          this.selectedVideoCategory = null;
        }
      }
    },
    editVideoCategoryTranslation(translation) {
      this.isEditingVideoCategoryTranslation = true;
      this.editedVideoCategoryTranslation = JSON.parse(JSON.stringify(translation));
    },
    async saveVideoCategoryTranslation() {
      let changes = {
        videoCategoryId: this.selectedVideoCategory.id,
        videoCategoryTranslationId: this.editedVideoCategoryTranslation.id,
        name: this.editedVideoCategoryTranslation.name,
        language: this.editedVideoCategoryTranslation.language,
      };
      this.isEditingVideoCategoryTranslation = false;

      let res = await axios.post(API_URL + "saveVideoCategoryTranslation", changes, { headers: authHeader() });
      //console.log('saveVideoCategoryTranslation res', res)
      if (res.status == 200) {
        let translationIndex = this.selectedVideoCategory.videos_categories_translations.findIndex(t => t.language == this.editedVideoCategoryTranslation.language);
        if (translationIndex >= 0) {
          this.selectedVideoCategory.videos_categories_translations[translationIndex] = res.data;
        }
        else {
          this.selectedVideoCategory.videos_categories_translations.push(res.data);
        }
        this.forceVideoCategoryTranslationsUpdate++;
      }
      else {
        this.openToast({ message: parseError(res), duration: 15000, color: "red" });
      }
    },

    getAddedAndRemovedIds(editList, originalList) {
      let addedIds = [];
      let removedIds = [];

      editList.forEach(vc => {
        let isPresent = false;
        for (let i = 0; i < originalList.length; i++) {
          if (originalList[i].id == vc.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          addedIds.push(vc.id);
        }
      });

      originalList.forEach(vc => {
        let isPresent = false;
        for (let i = 0; i < editList.length; i++) {
          if (editList[i].id == vc.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          removedIds.push(vc.id);
        }
      });

      return {
        addedIds,
        removedIds
      }
    },

    async videoCategoriesChanged() {
      let addedAndRemoved = this.getAddedAndRemovedIds(this.selectedVideo.editVideoCategories, this.selectedVideo.videos_categories);
      
      let changes = {
        videoId: this.selectedVideo.id,
        addedVideoCategories: addedAndRemoved.addedIds,
        removedVideoCategories: addedAndRemoved.removedIds,
      }

      let res = await axios.post(API_URL + "saveVideo", changes, { headers: authHeader() });
      if (res.status != 200) {
        this.openToast({ message: parseError(res), duration: 15000, color: "red" });
      }

      this.selectedVideo.videos_categories = this.selectedVideo.editVideoCategories;
    },
    async engineeringDepartmentsChanged() {
      let addedAndRemoved = this.getAddedAndRemovedIds(this.selectedVideo.editVideoEngineeringDepartments, this.selectedVideo.engineering_departments);
      
      let changes = {
        videoId: this.selectedVideo.id,
        addedEngineeringDepartments: addedAndRemoved.addedIds,
        removedEngineeringDepartments: addedAndRemoved.removedIds,
      }

      let res = await axios.post(API_URL + "saveVideo", changes, { headers: authHeader() });
      if (res.status != 200) {
        this.openToast({ message: parseError(res), duration: 15000, color: "red" });
      }

      this.selectedVideo.engineering_departments = this.selectedVideo.editVideoEngineeringDepartments;
    },
    async rolesChanged() {
      let addedAndRemoved = this.getAddedAndRemovedIds(this.selectedVideo.editVideoRoles, this.selectedVideo.roles);
      
      let changes = {
        videoId: this.selectedVideo.id,
        addedRoles: addedAndRemoved.addedIds,
        removedRoles: addedAndRemoved.removedIds,
      }

      let res = await axios.post(API_URL + "saveVideo", changes, { headers: authHeader() });
      if (res.status != 200) {
        this.openToast({ message: parseError(res), duration: 15000, color: "red" });
      }

      this.selectedVideo.roles = this.selectedVideo.editVideoRoles;
    }
  },
  async mounted() {
    this.setBreadcrumbs([{ text: this.$t("general.settings"), href: "/settings" }, { text: this.$t("general.videos"), href: "/settings/videos" },]);

    try {
      let res = await axios.get(API_URL + "videos", { headers: authHeader() });
      //console.log('getVideos', res)
      if (res && res.data) {
        this.videos = res.data.videos;
        this.videoCategories = res.data.videoCategories;
        this.availableLanguages = res.data.languages;
        this.engineeringDepartments = res.data.engineeringDepartments;
        this.roles = res.data.roles;
      }
    }
    catch(err) {
      this.$router.push("/");
    }
  },
};
</script>
<style scoped>
.add-video-btn {
  text-transform: none;
}
.no-transform {
  text-transform: none;
}
.active-video{
  border-radius:8px;
  background: rgba(0, 159, 77, 0.1);
  color:#009f4d;
}

.v-list {
  max-height: 500px;
  overflow: auto;
}
</style> 